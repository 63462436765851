/* Author: David Eglin

*/

$(function () {
  $('a.thumbnail').colorbox({
    scalePhotos: true,
    maxWidth: '90%',
    maxHeight: '90%',
  })
})
